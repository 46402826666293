import { useEffect, useState } from "react";

import c from "classnames";
import slugify from "react-slugify";
import { useQuery } from "@apollo/client";

import { FullWidthSection } from "@/types/page";
import { SplitHeading } from "@/components/FormElements/SplitHeading";
import { Button } from "@/components/FormElements/Button";
import { Icon } from "@/components/FormElements/Icon";
import { RECIPE_STAT_QUERY, STAT_QUERY } from "@/API/queries";
import { numberWithCommas } from "@/helpers/helperFunctions";
import { LoadingContainer } from "@/components/LoadingContainer";

import Styles from "./fullWidth.module.scss";

type Props = {
	content: FullWidthSection;
	children?: React.ReactNode;
	slug?: string;
	zigZagTop?: boolean;
	className?: string;
	position?: string;
};

export const FullWidth = ({
	content,
	children,
	slug,
	zigZagTop,
	className,
	position = "relative",
}: Props) => {
	const [mealsDelivered, setMealsDelivered] = useState(0);
	const [recipesEaten, setRecipesEaten] = useState(0);

	const { data, loading: mealLoading } = useQuery(STAT_QUERY, {
		context: { clientName: "subApp" },
		skip: !content.mealCounter,
	});

	const { data: recipeEatenData, loading: recipeLoading } = useQuery(
		RECIPE_STAT_QUERY,
		{
			context: { clientName: "subApp" },
			variables: {
				slug,
			},
			skip: !content.recipeEatenCount || !slug,
		}
	);

	useEffect(() => {
		if (!data || !content.mealCounter) {
			return;
		}
		setMealsDelivered(
			6129051 +
				(data.statistics.subscriptions + data.statistics.deliveries) * 50
		);
	}, [content.mealCounter, data]);

	useEffect(() => {
		if (!recipeEatenData || !content.recipeEatenCount) {
			return;
		}
		setRecipesEaten(recipeEatenData.statistics.subscriptions * 28 * 2);
	}, [content.recipeEatenCount, data, recipeEatenData, slug]);

	return (
		<section
			id={content.id}
			className={c("sectionSpacing", Styles.fullWidth, {
				[Styles["sideIcons-" + slugify(content.sideIcons)]]: content.sideIcons,
				[Styles["background-" + slugify(content.backgroundColour)]]:
					content.backgroundColour,
				[Styles.hasZigZagTop]: zigZagTop,
				[`${className}`]: !!className,
				[Styles["position-" + position]]: true,
			})}
		>
			{zigZagTop && <div className={Styles.zigzagBorder}></div>}
			<div className={c("container", Styles.contentWrapper)}>
				{content?.heading?.[0]?.headingLine1 && (
					<>
						{content.heading[0].split ? (
							<SplitHeading
								heirarchy={content.heading[0].sizing}
								line1={content.heading[0].headingLine1}
								line2={content.heading[0].headingLine2 || ""}
								colour={content.heading[0].secondaryLineColour}
							/>
						) : (
							<h2>{content.heading[0].headingLine1}</h2>
						)}
					</>
				)}

				{content?.mealCounter && (
					<LoadingContainer loading={mealLoading} light>
						<div className={c("h1", Styles.mealCounter)}>
							{numberWithCommas(mealsDelivered)}
						</div>
					</LoadingContainer>
				)}

				{content?.recipeEatenCount && (
					<LoadingContainer loading={recipeLoading} light>
						<div className={c("h1", Styles.mealCounter)}>
							{numberWithCommas(recipesEaten)}
						</div>
					</LoadingContainer>
				)}

				{content?.content && (
					<span
						className={c("paraLarge center", Styles.contentText)}
						dangerouslySetInnerHTML={{
							__html: content.content,
						}}
					/>
				)}

				{content?.iconPoints?.[0]?.icon &&
					content.iconPoints?.map((iconPoint, key) => (
						<div className={Styles.iconPoint} key={key}>
							<Icon
								icon={iconPoint.icon}
								height="48"
								width="48"
								noFill={true}
							/>
							<span>{iconPoint.text}</span>
						</div>
					))}
				{content?.stats?.[0]?.number && (
					<div className={Styles.stats}>
						{content.stats?.map((stat, key) => (
							<div className={c(Styles.stat)} key={key}>
								<span className={c("h1", Styles.statNumber)}>
									{stat.number}
								</span>
								<span className={c("h4", Styles.statDescription)}>
									{stat.description}
								</span>
							</div>
						))}
					</div>
				)}

				{content?.button?.[0]?.text && (
					<Button url={content.button[0].link} width="relative" sizing="large">
						{content.button[0].text}
					</Button>
				)}

				{children && children}
			</div>
		</section>
	);
};
