import { useState, useEffect, useRef, useCallback, FC, useMemo } from "react";

import c from "classnames";
import { useSwipeable } from "react-swipeable";

import { useMediaQueries } from "@/hooks/useMediaQueries";

import Styles from "./carousel.module.scss";

import { Icon } from "../FormElements/Icon";

type Props<PropsType> = {
	items?: Array<PropsType & { id: string | number }>;
	ItemComponent: FC<PropsType>;
	scrollAmount?: number;
	centerID?: number | string;
	reset?: number;
	hasControls?: boolean;
	hasLargeControls?: boolean;
	equalHeightChildren?: boolean;
};

export const Carousel = <T,>({
	items,
	ItemComponent,
	scrollAmount = 1,
	centerID,
	reset = 1,
	hasControls = false,
	hasLargeControls = false,
	equalHeightChildren = false,
}: Props<T>) => {
	const [itemWidth, setItemWidth] = useState<number>(0);
	const [innerWidth, setInnerWidth] = useState("100%");
	const [scrolledAmount, setScrolledAmount] = useState(0);
	const [perPage, setPerPage] = useState(1);
	const carousel = useRef<HTMLDivElement>(null);
	const itemRef = useRef<any>(null);
	const [hasCentered, setHasCentered] = useState<number | string>(0);
	const [margin, setMargin] = useState(0);
	const [scrollable, setScrollable] = useState(true);
	const [swipeCounter, setSwipeCounter] = useState(0);

	const { isMobile, isDesktop, hasLoaded } = useMediaQueries();

	const scrollCarousel = useCallback(
		(direction: "left" | "right") => {
			console.log(scrollable);
			if (!scrollable) {
				return;
			}
			if (direction === "left") {
				if (scrolledAmount < 0) {
					setSwipeCounter(swipeCounter - 1);
					setScrolledAmount(
						Math.min(
							scrolledAmount +
								(itemWidth / items?.length) * scrollAmount +
								margin * scrollAmount,
							0
						)
					);
				}
			} else {
				if (
					Math.abs(scrolledAmount) <
					parseInt(innerWidth) - (carousel?.current?.offsetWidth || 0)
				) {
					setSwipeCounter(swipeCounter + 1);
					setScrolledAmount(
						Math.max(
							scrolledAmount -
								(itemWidth / items?.length) * scrollAmount -
								margin * scrollAmount,
							-(parseInt(innerWidth) - (carousel?.current?.offsetWidth || 0))
						)
					);
				}
			}
		},
		[
			scrollable,
			scrolledAmount,
			swipeCounter,
			itemWidth,
			items?.length,
			scrollAmount,
			margin,
			innerWidth,
		]
	);

	const moveCarousel = useCallback(
		(index: number) => {
			setScrolledAmount(
				0 - ((itemWidth / items?.length) * index + margin * index)
			);
			setSwipeCounter(index);
		},
		[itemWidth, items?.length, margin]
	);

	useEffect(() => {
		if (!hasLoaded) {
			return;
		}
		if (!isMobile) {
			setMargin(20);
		} else {
			setMargin(10);
		}
	}, [hasLoaded, isMobile]);

	useEffect(() => {
		if (
			!centerID ||
			!items ||
			!itemWidth ||
			!carousel.current?.offsetWidth ||
			(hasCentered === centerID && reset < 2) ||
			margin === 0 ||
			!scrollable
		) {
			return;
		}

		const centeredItemIndex = items.findIndex(
			(item) =>
				typeof item === "object" && item && "id" in item && item.id === centerID
		);

		const centeredScrolledAmount =
			-(centeredItemIndex * (itemWidth / items?.length)) -
			margin * centeredItemIndex +
			((carousel.current?.offsetWidth || 0) / 2 -
				itemWidth / items?.length / 2);

		if (centeredScrolledAmount < 0) {
			setScrolledAmount(centeredScrolledAmount);
		} else {
			setScrolledAmount(0);
		}

		setHasCentered(centerID);
	}, [centerID, hasCentered, itemWidth, items, margin, scrollable, reset]);

	const handlers = useSwipeable({
		onSwipedLeft: () => scrollCarousel("right"),
		onSwipedRight: () => scrollCarousel("left"),
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
	});

	const getMap = useCallback(() => {
		if (!itemRef.current) {
			// Initialize the Map on first usage.
			itemRef.current = new Map();
		}
		return itemRef.current;
	}, []);

	useEffect(() => {
		const setWidths = () => {
			const map = getMap();

			let offsetWidth = 0;

			map.forEach((value: HTMLDivElement) => {
				offsetWidth = offsetWidth + value.offsetWidth;
			});

			const newItemWidth = offsetWidth;

			setItemWidth(newItemWidth || 100);

			if (newItemWidth <= (carousel?.current?.offsetWidth || 0)) {
				setScrollable(false);
				setInnerWidth(`${carousel?.current?.offsetWidth}px`);
			} else {
				setScrollable(true);
				setInnerWidth(`${newItemWidth + margin * (items?.length - 1)}px`);
			}

			setPerPage(
				Math.floor((carousel?.current?.offsetWidth || 0) / newItemWidth)
			);
		};

		// if the DOM isn't ready, wait for it to be
		if (itemRef && carousel.current && items?.length) {
			setWidths();
		}
	}, [getMap, items, margin]);

	const carouselItems = useMemo(() => {
		return items
			?.map((item, i) =>
				typeof item === "object" && item ? (
					// TODO: Refactor to remove the index as the key, this causes bugs
					<ItemComponent
						{...item}
						key={item.id}
						forwardRef={(node: any) => {
							const map = getMap();
							if (node) {
								map.set(item.id, node);
							} else {
								map.delete(item.id);
							}
						}}
					/>
				) : null
			)
			.filter((val) => !!val);
	}, [ItemComponent, getMap, items]);

	return (
		<>
			{hasLargeControls && isDesktop && (
				<div className={c(Styles.largeControl)}>
					<button
						className={c(Styles.largeControlBtn)}
						type="button"
						onClick={() => scrollCarousel("left")}
					>
						<Icon icon="CircleArrowLeft" width="48px" height="48px" />
					</button>
				</div>
			)}
			<div
				{...handlers}
				className={c(Styles.carouselCont, {
					[Styles.desktopMaxWidth]: hasLargeControls,
				})}
			>
				<div className={Styles.carousel} ref={carousel}>
					<div
						className={c(Styles.carouselInner, {
							[Styles.notScrollable]: !scrollable,
							[Styles.equalHeight]: equalHeightChildren,
						})}
						style={{ left: scrolledAmount, width: innerWidth }}
					>
						{carouselItems}
					</div>
				</div>

				{hasControls && (
					<div className={Styles.controls}>
						{items.map(({ id }, index) => (
							// TODO: Refactor to remove the index as the key, this causes bugs
							<div
								className={c(Styles.controlButton, {
									[Styles.active]: swipeCounter === index,
								})}
								key={id}
								onClick={() => moveCarousel(index)}
							></div>
						))}
					</div>
				)}
			</div>
			{hasLargeControls && isDesktop && (
				<div className={c(Styles.largeControl)}>
					<button
						className={c(Styles.largeControlBtn)}
						type="button"
						onClick={() => scrollCarousel("right")}
					>
						<Icon icon="CircleArrowRight" width="48px" height="48px" />
					</button>
				</div>
			)}
		</>
	);
};
